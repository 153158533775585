<template>
  <div
    v-for="(entry, idx) in data.entries"
    :key="idx"
    class="bg-white border border-textGray-100 rounded-md p-6 shadow-sm"
  >
    <div class="grid grid-cols-1 gap-8">
      <div
        :class="
          role === 'hq' ? 'grid grid-cols-2 gap-8' : 'grid grid-cols-1 gap-8'
        "
      >
        <v-select
          v-if="role === 'hq'"
          :options="practices"
          v-model="localPractice[idx]"
          label="practice_name"
          buttonWidth="w-full"
          placeholder="Select Practice"
          @update:modelValue="setPractice($event, idx)"
          :class="isEmpty[idx]?.practice ? '' : 'border rounded-md border-red'"
        />
        <DatePicker
          placeholder="Date"
          class="border rounded"
          :selectedDate="entry.date"
          @selected-date="(date) => setEntryDate(date, idx)"
          @cleared-date="(date) => setEntryDate(date, idx)"
          :class="isEmpty[idx]?.date ? ' border-gray-300' : 'border-red'"
        />
      </div>
      <div class="grid grid-cols-2 gap-8">
        <v-select
          :options="indicators"
          v-model="localIndicator[idx]"
          label="name"
          buttonWidth="w-full"
          placeholder="Select Indicator"
          :clearable="isClearable"
          @update:modelValue="setIndicator($event, idx)"
          :class="isEmpty[idx]?.indicator ? '' : 'border rounded-md border-red'"
        />
        <v-select
          :options="subIndicators"
          v-model="localSubindicator[idx]"
          :disabled="!localIndicator"
          :clearable="isClearable"
          label="name"
          buttonWidth="w-full"
          placeholder="Select Sub-Indicator"
          @update:modelValue="setSubIndicator($event, idx)"
          :class="
            isEmpty[idx]?.sub_indicator ? '' : 'border rounded-md border-red'
          "
        />
      </div>
      <div class="grid grid-cols-2 gap-8">
        <div>
          <input
            type="number"
            class="w-full rounded-md px-4 py-2 outline-none"
            placeholder="Patient Count"
            v-model="entry.patient_count"
            :class="
              isEmpty[idx]?.patient_count
                ? ' border border-gray-300'
                : 'border border-red'
            "
          />
        </div>
        <div>
          <input
            type="number"
            class="w-full border border-gray-300 rounded-md px-4 py-2 outline-none"
            placeholder="Missing Patients"
            v-model="entry.missing_patient"
            :class="
              isEmpty[idx]?.missing_patient
                ? 'border border-gray-300'
                : 'border border-red'
            "
          />
          <small
            v-show="entry.missing_patient > entry.patient_count"
            class="text-red-500"
          >
            Missing patient count should be less than total patient count</small
          >
        </div>
      </div>
      <div class="grid grid-cols-2 gap-8">
        <div>
          <input
            type="number"
            class="w-full border border-gray-300 rounded-md px-4 py-2 outline-none"
            placeholder="Bench Mark Points"
            v-model="entry.benchmark_points"
            :class="
              isEmpty[idx]?.benchmark_points
                ? 'border border-gray-300'
                : 'border border-red'
            "
          />
        </div>
        <div>
          <input
            type="number"
            class="w-full border border-gray-300 rounded-md px-4 py-2 outline-none"
            placeholder="Bench Mark%"
            v-model="entry.benchmark_percentage"
            :class="
              isEmpty[idx]?.benchmark_percentage
                ? 'border border-gray-300'
                : 'border border-red'
            "
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-8">
        <div>
          <input
            type="number"
            class="w-full border border-gray-300 rounded-md px-4 py-2 outline-none"
            placeholder="Achieved Points"
            v-model="entry.achieved_points"
            :class="
              isEmpty[idx]?.achieved_points
                ? ' border border-gray-300'
                : 'border border-red'
            "
          />
          <small
            v-show="entry.achieved_points > entry.benchmark_points"
            class="text-red-500"
          >
            Achieved points should be less than Benchmark points</small
          >
        </div>
      </div>
    </div>
    <button
      v-if="props.mode === 'create'"
      @click="deleteQofEntry(idx)"
      class="flex items-center justify-center float-right mt-6 border border-red text-red p-3 rounded-md w-28"
      :class="{ 'opacity-50 pointer-events-none': data?.entries?.length < 2 }"
    >
      <span class="material-icons w-8">delete_outlined</span>Delete
    </button>
  </div>
  <div v-if="props.mode === 'create'" class="flex justify-end">
    <button
      @click="addNewData"
      class="w-full p-4 rounded-md border border-teal text-teal"
    >
      + Add New Data
    </button>
  </div>
  <div class="flex justify-between mt-8">
    <button
      @click="router.push({ name: 'hq-qof-view-edit' })"
      class="bg-transparent border-teal border w-34 h-12 font-light text-teal rounded-lg"
    >
      Cancel
    </button>
    <button
      class="bg-teal h-12 w-34 font-light text-white rounded-md"
      @click="createQofEntry"
      :class="
        props.mode !== 'create' && !isDataDirty
          ? 'cursor-block bg-opacity-25'
          : ''
      "
      :disabled="props.mode === 'create' ? isLoading : !isDataDirty"
    >
      {{ props.mode === "create" ? "Done" : "Update" }}
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, defineProps, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
import icon from "@/assets/icons/warning.svg";
import Swal from "sweetalert2";

const store = useStore();
const router = useRouter();
const route = useRoute();
const role = localStorage.getItem("role");
const isLoading = ref(false);
const isEmpty = ref([
  {
    practice: true,
    indicator: true,
    sub_indicator: true,
    patient_count: true,
    missing_patient: true,
    benchmark_percentage: true,
    benchmark_points: true,
    achieved_points: true,
    date: true,
  },
]);

const props = defineProps({
  mode: {
    type: String,
    default: "create",
  },
  qofEntry: {
    type: Object,
    required: false,
  },
});

const subIndicators = ref([]);
const localIndicator = ref([]);
const localPractice = ref([]);
const localSubindicator = ref([]);

const data = ref({
  entries: [
    {
      practice:
        role === "manager" ? parseInt(localStorage.getItem("practice")) : null,
      indicator: null,
      sub_indicator: null,
      patient_count: null,
      missing_patient: null,
      benchmark_percentage: null,
      benchmark_points: null,
      achieved_points: null,
      date: null,
    },
  ],
});

const rules = {
  data: {
    entries: {
      $each: helpers.forEach({
        practice: { required },
        indicator: { required },
        sub_indicator: { required },
        patient_count: { required },
        missing_patient: {
          required,
          missingPatientLessThanPatientCount(value, _, id) {
            return value <= data.value.entries[id].patient_count;
          },
        },
        benchmark_percentage: { required },
        benchmark_points: { required },
        achieved_points: {
          required,
          achievedPointsLessThanBenchmarkPoints(value, _, id) {
            return value <= data.value.entries[id].benchmark_points;
          },
        },
        date: { required },
      }),
    },
  },
};

const v$ = useVuelidate(rules, { data });

const isDataDirty = computed(() => {
  if (props.mode !== "create")
    for (const entry of data.value.entries) {
      if (
        (entry.practice !== null &&
          entry.practice !== props.qofEntry?.practice.id) ||
        (entry.indicator !== null &&
          entry.indicator !== props.qofEntry?.indicator.id) ||
        (entry.sub_indicator !== null &&
          entry.sub_indicator !== props.qofEntry?.sub_indicator.id) ||
        (entry.patient_count !== null &&
          entry.patient_count !== props.qofEntry?.patient_count) ||
        (entry.missing_patient !== null &&
          entry.missing_patient !== props.qofEntry?.missing_patient) ||
        (entry.benchmark_percentage !== null &&
          entry.benchmark_percentage !==
            props.qofEntry?.benchmark_percentage) ||
        (entry.benchmark_points !== null &&
          entry.benchmark_points !== props.qofEntry?.benchmark_points) ||
        (entry.achieved_points !== null &&
          entry.achieved_points !== props.qofEntry?.achieved_points) ||
        (entry.date !== null && entry.date !== props.qofEntry?.date)
      ) {
        return true;
      }
    }
  return false;
});

const isClearable = computed(() => (props.mode === "create" ? true : false));

watch(
  () => props.qofEntry,
  () => {
    if (props.mode == "edit" && props.qofEntry) {
      data.value.entries[0].date = props.qofEntry?.date;
      data.value.entries[0].patient_count = props.qofEntry?.patient_count;
      data.value.entries[0].missing_patient = props.qofEntry?.missing_patient;
      data.value.entries[0].benchmark_percentage =
        props.qofEntry?.benchmark_percentage;
      data.value.entries[0].benchmark_points = props.qofEntry?.benchmark_points;
      data.value.entries[0].achieved_points = props.qofEntry?.achieved_points;
      setIndicator(props.qofEntry?.indicator, 0);
      setPractice(props.qofEntry?.practice, 0);
      setSubIndicator(props.qofEntry?.sub_indicator, 0);
    }
  },
  { deep: true }
);

onMounted(async () => {
  if (role === "hq") {
    await store.dispatch("hqQof/fetchIndicators");
    await store.dispatch("patientSurveys/loadPractices");
  } else if (role === "manager") {
    await store.dispatch("maQof/fetchIndicators");
  }
});

const practices = computed(() => {
  return store.getters["patientSurveys/getPractices"];
});

const indicators = computed(() => {
  return store.getters[
    role === "hq" ? "hqQof/getIndicators" : "maQof/getIndicators"
  ];
});

const setEntryDate = (selectedDate, id) => {
  if (selectedDate) {
    data.value.entries[id].date = selectedDate;
  } else {
    data.value.entries[id].date = selectedDate;
  }
};

const setPractice = (practice, idx) => {
  data.value.entries[idx].practice = practice.id;
  localPractice.value[idx] = practice.practice_name;
};

const setIndicator = (indicator, idx) => {
  data.value.entries[idx].indicator = indicator.id;
  localIndicator.value[idx] = indicator.name;
  if (props.mode === "edit") {
    indicators.value.forEach((ind) => {
      if (indicator.id === ind.id) {
        subIndicators.value = ind.sub_indicators;
      }
    });
  } else {
    subIndicators.value = indicator.sub_indicators;
  }
};

const setSubIndicator = (subIndicator, idx) => {
  data.value.entries[idx].sub_indicator = subIndicator.id;
  localSubindicator.value[idx] = subIndicator.name;
};

const addNewData = async () => {
  await v$.value.$validate();
  if (v$.value.$error) {
    isEmpty.value = data.value.entries.map((error) => {
      return error;
    });
    return;
  }
  data.value.entries.push({
    practice:
      role === "manager" ? parseInt(localStorage.getItem("practice")) : null,
    indicator: null,
    sub_indicator: null,
    patient_count: null,
    missing_patient: null,
    benchmark_percentage: null,
    benchmark_points: null,
    achieved_points: null,
    date: null,
  });
  isEmpty.value.push({
    practice: true,
    indicator: true,
    sub_indicator: true,
    patient_count: true,
    missing_patient: true,
    benchmark_percentage: true,
    benchmark_points: true,
    achieved_points: true,
    date: true,
  });
};

const deleteQofEntry = (index) => {
  Swal.fire({
    padding: "40px",
    width: "40%",
    background: `rgba(225, 249, 240, 1)`,
    iconHtml: `<img src="${icon}" />`,
    title: "Are you sure you want to delete this component?",
    showCancelButton: true,

    cancelButtonText: "Cancel",
    confirmButtonText: "Confirm",
    reverseButtons: true,
    confirmButtonColor: "rgba(0, 164, 153, 1)",
    customClass: {
      title: "custom-delete-title",
      popup: "border-radius",
      content: "custom-text",
      icon: "icon-border",
      cancelButton: "custom-cancel-button",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      data.value.entries.pop(index);
    } else {
      return;
    }
  });
};

const createQofEntry = async () => {
  await v$.value.$validate();
  if (v$.value.$error) {
    isEmpty.value = data.value.entries.map((error) => {
      return error;
    });
    return;
  }
  isLoading.value = true;
  if (props.mode === "edit") {
    data.value.entries[0].qof_entry = route.params.id;
    if (role === "hq") {
      await store.dispatch("hqQof/updateQofEntry", data.value.entries[0]);
      router.push({ name: "hq-qof-view-edit" });
    } else if (role === "manager") {
      await store.dispatch("maQof/updateQofEntry", data.value.entries[0]);
      router.push({ name: "ma-qof-view-edit" });
    }
  } else {
    if (role === "hq") {
      await store.dispatch("hqQof/createQofEntry", data.value).then(() => {
        isLoading.value = false;
      });
      router.push({ name: "hq-qof-view-edit" });
    } else if (role === "manager") {
      await store
        .dispatch("maQof/createQofEntry", {
          practice: parseInt(localStorage.getItem("practice")),
          entries: data.value?.entries,
        })
        .then(() => {
          isLoading.value = false;
        });
      router.push({ name: "ma-qof-view-edit" });
    }
  }
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 7px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
.swal2-cancel.swal2-styled {
  border-color: red !important;
}
::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}
</style>
