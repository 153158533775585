<template>
  <div class="flex flex-col gap-8">
    <span class="text-lg font-medium">Add QoF Data</span>
    <CreateEditQofData :mode="mode" :qofEntry="role === 'manager' ? qofEntry.value : qofEntry" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import CreateEditQofData from '@/components/newUi/QOF/CreateEditQofData.vue';

const store = useStore()
const route = useRoute()

const role = localStorage.getItem('role');

const mode = route.params.mode;
const entryId = route.params.id ? route.params.id : null;

const qofEntry = ref({})

if (mode == 'edit' && entryId) {
  onMounted(async () => {
    if (role === 'manager') {
      await store.dispatch('maQof/fetchSingleQofEntry', entryId)
      qofEntry.value = computed(() => {
        return store.getters['maQof/getSingleQofEntry'];
      })
    } else if (role === 'hq') {
      qofEntry.value = await store.dispatch('hqQof/fetchSingleQofEntry', entryId)
    }
  })
}
</script>
